import { Component, Input, OnInit } from '@angular/core';
import { AlertController, IonNav, PopoverController } from '@ionic/angular';
import { PaymentService } from 'src/app/services/payment.service';
import { ContactFormPage } from '../contact-form/contact-form.page';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentPlanRequestPage } from '../payment-plan-request/payment-plan-request.page';
import { ViewPaymentPlanPage } from '../view-payment-plan/view-payment-plan.page';
import { PaymentOptionPage } from '../payment-plan/payment-option/payment-option.page';
import { PaymentPlanOptionComponent } from '../account-details/payment-plan-option/payment-plan-option.component';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-case-detail',
  templateUrl: './case-detail.page.html',
  styleUrls: ['./case-detail.page.scss'],
})
export class CaseDetailPage implements OnInit {
  @Input() selectedProfile;
  @Input() portalSettings;
  field_list = [];
  fieldLabels = {};
  casePaymentLinks = [];
  arrangements;
  isPreviousPaymentTab = false;
  selectedTab = 'account';
  documentArray=[];
  caseVariableArray: any = [];
  dateFormat='DD/MM/YYYY';
  timeFormat='HH:MM:SS';
  fileUploadForm:FormGroup;
  file:File
  panel_list = [];
  arr_option: any = {};
  isDisputeAccountVisible: boolean = true;
  allowOnlyGroupArrangementForMultipleCases: boolean = false;
  acceptSettlementOffer: boolean = false;
  settlementAmountWithoutSymbol;
  settlementAmount = 0;

  constructor(
    private paymentService: PaymentService,
    private ionNav: IonNav,
    public caseService: CaseService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private alertController: AlertController,
    private authService: AuthService,
    private popoverController: PopoverController
  ) {}

  ngOnInit() {
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    //Used for Dispute account is visible or not in Account Detailspor
    this.isDisputeAccountVisible = portalSettings.portal_details.is_dispute_account_visible ?? this.isDisputeAccountVisible;

    //Used for allow group arrangement for multiple cases
      this.allowOnlyGroupArrangementForMultipleCases = portalSettings.portal_details.allow_only_group_arrangement_for_multiple_cases ?? this.allowOnlyGroupArrangementForMultipleCases;

    if(portalSettings.panel_list) {
      this.panel_list = portalSettings.panel_list;
    }
    if (portalSettings?.fieldLabels) {
      this.fieldLabels = portalSettings.fieldLabels;
    }
    if (portalSettings?.field_list) {
      this.field_list = portalSettings?.field_list
        ? portalSettings?.field_list
        : [];
    }
    if (portalSettings?.arr_option) {
      this.arr_option = portalSettings?.arr_option
        ? portalSettings?.arr_option
        : { request: true, perform: false };
    }
    this.getCaseDocuments();
    this.initForm()
    this.getArrangements();
    this.getCaseVariables();
  }

  initForm() {
    this.fileUploadForm = this.formBuilder.group({
      selectedFile: [null, Validators.required],
    });
  }

  getCaseFieldValue(value, caseField) {
    const currentCase = value;
    const res = caseField.split('.');
    res.forEach((r) => {
      let r1 = r.split('[');
      if (r1.length > 1) {
        value = value[r1[0]][0];
      } else {
        value = value[r];
      }
      if (Array.isArray(value)) {
        if (value[0]) {
          value = value[0];
        } else {
          value = [];
        }
      }
      if (r === 'address_postcode') {
        if (!value && currentCase.debtor.addresses[0].address_postcode) {
          value = currentCase.debtor.addresses[0].address_postcode;
        }
      }
      if (r === 'hold_until') {
        if (value != null && value > new Date()) {
          value = 'Yes';
        } else {
          value = 'No';
        }
      }
    });
    return value ? value : '-';
  }

  async presentActionSheet(caseData) {
    this.paymentService.presentActionSheet(caseData);
  }

  //It'll submit the payment as accepting settlement offer.
  async presentActionSheetSettlemtntOffer(caseData, fullamount = true) {
    this.acceptSettlementOffer = true;
    caseData.acceptSettlementOffer = this.acceptSettlementOffer;
    caseData.settlementAmount = this.settlementAmountWithoutSymbol;
    this.paymentService.presentActionSheet(caseData, fullamount);
  }

  getOutstandingBalance(data) {
    if (!data.d_outstanding) return '-';
    else {
      if (isNaN(data.d_outstanding[0])) return data.d_outstanding;
      else if (data.currency_symbol) {
        return data.currency_symbol + data.d_outstanding;
      }
      return data.d_outstanding;
    }
  }

  async goToDisputeForm() {
    this.ionNav.push(ContactFormPage, {
      caseId: this.selectedProfile.id,
      placeholder: 'Enter dispute details...',
      textLabel:
        'If you need to dispute this account, please inform us here in this comments screen and let us know.',
      formName: 'dispute',
    });
  }
  async getCaseDocuments()
  {
    this.caseService.getCaseDocuments(this.selectedProfile.id).subscribe(res=>{
    this.documentArray=res['return'].data;
    this.dateFormat=localStorage.getItem("dateFormat");
    this.timeFormat=JSON.parse(localStorage.getItem("portal_settings")).datepicker_time_format
    this.documentArray.forEach(
        (element,index) => {
        this.documentArray[index].document_history.time = moment(element.document_history.time).format(this.dateFormat+" "+this.timeFormat)
         })
      })
  }
  onFileSelected(event:any){
     this.file=event.target.files[0];
  }
  async uploadDocument(){
    if(this.fileUploadForm.get('selectedFile').value){
      this.fileUploadForm.get('selectedFile').reset();
      this.caseService.uploadDocument(this.file,this.selectedProfile.id).subscribe(res=>{
      if(res['status']==200){
      this.commonService.showToast(res['message'],'success');
      this.getCaseDocuments()
    }
    else{
      this.commonService.showToast(res['message'],'danger');
    }
     })
}}
  async deleteDocument(documentId){
    if(documentId){
      const alert = await this.alertController.create({
        message: 'Are You Sure ?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {},
          },
          {
            text: 'delete file',
            role: 'OK',
            handler: () => {
            this.caseService.deleteDocument(documentId).subscribe(res=>{
              if(res['status']==200){
                this.commonService.showToast(res['data'].message,'success');
                this.getCaseDocuments()
              }
              else{
                this.commonService.showToast(res['data'].message,'danger');
              }
                 })
            },
          },
        ],
      });

      await alert.present();
}
}
async getArrangements(){
   //filter it for arrangement status
   this.arrangements=this.selectedProfile.arranagement.arrangement_schedule
   this.arrangements = this.arrangements.filter(arrangement =>  arrangement.payment_action.id === "-4");
   this.arrangements.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
}
  async goToPaymentPlanProposal(caseData) {
    this.ionNav.push(PaymentPlanRequestPage, {
      caseId: caseData.id,
      totalOutstanding: caseData.d_outstanding,
      isGroupArrangement: false,
      debtorDetails: this.authService.debtorDetails,
    });
  }
  async goToPaymentOption(caseData) {
    this.caseService.setPaymentData({
      caseId: caseData.id,
      caseData,
    });
    this.ionNav.push(PaymentOptionPage, {
      caseData,
      planType:'plan',
      debtorDetails: this.authService.debtorDetails,
    });
  }

  async goToPaymentViewOption(caseData) {
    this.ionNav.push(ViewPaymentPlanPage, {
      caseId: caseData.id,
      currentCase: caseData,
    });
  }

  async presentPopover(ev: any, item: any) {
    const popover = await this.popoverController.create({
      component: PaymentPlanOptionComponent,
      componentProps: {
        case: item,
      },
      event: ev,
      translucent: true,
    });
    await popover.present();
    const { data } = await popover.onDidDismiss();
    if (data?.case && typeof this[data?.type] === 'function') {
      this[data.type](data.case);
    }
  }
  async getCaseVariables() {
    //Fetch Settlement offer from the case variables.
    this.caseService.getCaseVariables(this.selectedProfile.id, true).subscribe(res=>{
      this.caseVariableArray = res['data'];
      if(typeof(res['data'].custom_auto_vars.settlement_amount) != "undefined") {
        this.settlementAmountWithoutSymbol = (res['data'].custom_auto_vars?.settlement_amount).toFixed(2);
      } else {
        this.settlementAmountWithoutSymbol = this.settlementAmount;
      }
      this.settlementAmount = this.selectedProfile.currency_symbol + this.settlementAmountWithoutSymbol;
    })
  }
}
