import { Component, OnInit, Input } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { CaseService } from 'src/app/services/case.service';
import { PaymentDatePage } from '../payment-date/payment-date.page';

@Component({
  selector: 'app-custom-pay-options',
  templateUrl: './custom-pay-options.page.html',
  styleUrls: ['./custom-pay-options.page.scss'],
})
export class CustomPayOptionsPage implements OnInit {
  @Input() caseData;
  @Input() amount;
  @Input() planType = 'plan';
  @Input() total = 0;
  @Input() caseList = [];
  paymentFrequencyLabel = {
    3: 'week',
    4: 'month',
    6: 'biweek',
  }
  constructor(private caseService: CaseService, private ionNav: IonNav) {}

  ngOnInit() {}

  async mapToRequiredPaymentPlanOption(
    amount = 0,
    freq = '0',
    customPlan = 0,
    text = null,
    paymentFrequencyOption = null,
    scheduledAmount = '',
    limit = '1',
    total= 0,
    caseList = []
  ) {
    this.caseService.setPaymentData({
      paymentFrequencyOption,
      text,
      amount,
      scheduledAmount,
      freq,
      customPlan,
      limit,
      total,
      caseList
      
    });
  }
  save(option) {
    let freq;
    if (option == 'week') {
      freq = 3;
      this.mapToRequiredPaymentPlanOption(
        this.amount,
        "3",
        0,
        "",
        'week',
        "0",
        '1',
        this.total,
        this.caseList
      );
    } else if (option == 'fortnight') {
      freq = 6;
      this.mapToRequiredPaymentPlanOption(
        this.amount,
        "6",
        0,
        "",
        'fortnight',
        "0",
        '1',
        this.total,
        this.caseList
      );
    } else if (option == 'month') {
      freq = 4;
      this.mapToRequiredPaymentPlanOption(
        this.amount,
        '4',
        0,
        "",
        'month',
        "0",
        '1',
        this.total,
        this.caseList
      );
    }
    let currency=this.caseData.currency_symbol;
    let limit = Math.ceil(this.total/this.amount);
    let amount = parseFloat(this.amount);
    let balance = this.total - amount * limit;
      balance = Math.round(balance * 100) / 100;
    if (balance > 0) {
      amount = Math.round((amount + balance) * 100) / 100;
    }
    let last_emi = amount;
    let emi_balance = (amount * limit) - this.total;
    if (emi_balance > 0) {
      last_emi = amount - emi_balance;
    } else {
      last_emi = amount + emi_balance;
    }
    let scheduledAmount = parseFloat(amount.toString()).toFixed(2);
    let text =
      limit +
      ' payments of <strong class="font-weight-bold">' +
      currency +
      scheduledAmount + '</strong>'+
      ' ' +
      this.paymentFrequencyLabel[freq] +
      'ly' + ' (Final installment: ' + last_emi.toFixed(2) + ')';
    this.ionNav.push(PaymentDatePage, { caseData: this.caseData , planType: this.planType, selectedPlanData: text});
  }
}
