import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, NavigationExtras } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeGuard implements CanActivate {
  constructor(
    public router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let token = localStorage.getItem('remote_token');
    let is_login = localStorage.getItem('is_login');

    if(!token && is_login) {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          url: state.url,
        },
      }
      this.router.navigate(['/login/terms'], navigationExtras);
      return false;
    }

    let dynamicLogin = 0;
    if (route.routeConfig.path == 'home') {
      let dpa_check = ''; let form_slug = ''; let case_id = ''; let debtor_id = '';
      if (Object.keys(route.queryParams).length > 0) {
        dpa_check = route.queryParams['dpa'];
        form_slug = route.queryParams['fslug'];
        case_id = route.queryParams['cid'];
        debtor_id = route.queryParams['did'];

        if (localStorage.getItem('debtor_id') != debtor_id || localStorage.getItem('dpa_check') != dpa_check
          || localStorage.getItem('form_slug') != form_slug || localStorage.getItem('case_id') != case_id) {
          localStorage.removeItem('remote_token');
        }
        localStorage.setItem('dpa_check', dpa_check);
        localStorage.setItem('form_slug', form_slug);
        localStorage.setItem('case_id', case_id);
        localStorage.setItem('debtor_id', debtor_id);
      } else {
        dynamicLogin = 1;
      }
    }

    token = localStorage.getItem('remote_token');
    if (token) {
      if (dynamicLogin && localStorage.getItem('default_webform_configured') == 'false') {
        this.router.navigate(['main/details'], {
          replaceUrl: true // Add this option
        });
        return false;
      } else if(Object.keys(route.queryParams).length == 0 && localStorage.getItem('default_webform_configured') != 'false'){
        let navigationExtras: NavigationExtras = {
          queryParams: {
            fslug: localStorage.getItem('form_slug'),
            cid: localStorage.getItem('case_id'),
            did: localStorage.getItem('debtor_id'),
            dpa: localStorage.getItem('dpa_check')
          },
          replaceUrl: true
        };
        this.router.navigate(['home'],navigationExtras);
        return false;
      }else {
        return true;
      }
    } else {
      let navigationExtras: NavigationExtras = {};
      if (dynamicLogin == 1) {
        navigationExtras = {
          queryParams: {
            dynamic: dynamicLogin,
          }
        };
      } else {
        localStorage.removeItem('dynamicLogin');
      }
      this.router.navigate(['login'], navigationExtras);
      return false;
    }
  }

}
