import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-payment-plan-option',
  templateUrl: './payment-plan-option.component.html',
  styleUrls: ['./payment-plan-option.component.scss'],
})
export class PaymentPlanOptionComponent implements OnInit {
  @Input() case: any;
  constructor(private popoverController: PopoverController,
    private alertController: AlertController,
  ) {}
  ngOnInit() {}
  goToPaymentPlan(type: string) {
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));

    /* @see LU-170 remove special char from outstanding amout which is compare to payment limit amount*/
    let outstanding = this.getOutstandingWithoutSymbol(this.case);
    let paymentLimitMessage;
    for (const arrangement of portalSettings.arrangement.plan) {
      paymentLimitMessage = this.getMessageByRange(arrangement, outstanding);
      if (paymentLimitMessage) {
        break; // Exit loop if a matching message is found
      }
    }

    if (paymentLimitMessage) {
      this.showAlert(paymentLimitMessage);
    }
    this.popoverController.dismiss({ case: this.case, type });
  }


  /**
   * @see LU-170 Debtor Portal-Arrangement rules/logic for allowing debtors to set up arrangements
   * @param object data
   * @param string outstanding
   * @return string|null
   */
  getMessageByRange(data, outstanding) {
    const { range, min, max, payment_limit_message } = data;
    switch (range) {
      case 'SMALLER_EQUALS':
        if (parseFloat(outstanding) < parseFloat(max)) {
          return payment_limit_message;
        }
        break;
      case 'RANGE_BETWEEN':
        if (parseFloat(outstanding) < parseFloat(max) && parseFloat(outstanding) >= parseFloat(min)) {
          return payment_limit_message;
        }
        break;
      case 'BIGGER_EQUALS':
        if (parseFloat(outstanding) > parseFloat(min)) {
          return payment_limit_message;
        }
        break;
      default:
        return '';
    }
  }

  /**
   * Show alert for payment limit
   * @param string message
   */
  async showAlert(message:string) {
    const alert = await this.alertController.create({
      header: 'Payment Amount Limit Message',
      message: message,
      cssClass:'custom-alert-class',
      buttons: [
        {
          text: 'Ok',
          role: 'OK',
          handler: () => {
            window.location.href = '/viewmycase';
          },
        },
      ],
    });
    // dismiss when clicking outside the alert
    alert.onDidDismiss().then(() => {
      window.location.href = '/viewmycase'; //redirect to main page
    });
    await alert.present();
  }

  getOutstandingWithoutSymbol(caseData) {
    const amount = caseData?.d_outstanding.replace(
      caseData?.currency_symbol,
      ''
    );
    return parseInt(amount.replace(',', ''));
  }
}
