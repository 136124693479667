import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, NavigationExtras, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MainGuard implements CanActivate {
  constructor(
    public router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let token = localStorage.getItem('remote_token');
    let is_login = localStorage.getItem('is_login');
    if (token) {
      return true;
    } else {
      if(is_login) {
        let navigationExtras: NavigationExtras = {
          queryParams: {
            url: state.url,
          },
        }
        this.router.navigate(['/login/terms'], navigationExtras);
        return false;
      }
      let navigationExtras: NavigationExtras = {
        queryParams: {
          dynamic: 1,
        }
      };
      this.router.navigate(['login'],navigationExtras);
      return false;
    }
  }

}
