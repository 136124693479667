import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-view-payment-plan',
  templateUrl: './view-payment-plan.page.html',
  styleUrls: ['./view-payment-plan.page.scss'],
})
export class ViewPaymentPlanPage implements OnInit {

  @Input() caseId;
  @Input() currentCase;
  debtorId;
  outstanding;
  currentArrangementString = '';
  currArrangement;
  activeArrangements: any = {
    currentArrangements: {
      show: true,
    },
    scheduleArrangements: {
      show: true,
      data: null,
    },
  };
  frequencies = [
    '',
    '',
    'One time final payment',
    'Weekly by day of the week',
    'Monthly by date',
    'Monthly by day - e.g. "1st friday',
    'Fortnightly',
    '4 weekly',
    'Last day of the month',
    'First day of the month',
    'Last work day of the month',
    'Last Friday of the month',
  ];
  isGroupArrangement = false;
  baseOutstanding;
  dateFormat = 'DD-MM-YYYY';
  currency_symbol;
  freq: string;
  constructor() {}

  ngOnInit() {}
  async ionViewWillEnter() {
    this.getActiveArrangements();
  }

  toggleShow(object) {
    object.show = !object.show;
  }
  isShown(object) {
    return object.show;
  }
  async getActiveArrangements() {
    if (localStorage.getItem('dateFormat'))
      this.dateFormat = localStorage.getItem('dateFormat');
    this.currency_symbol = this.currentCase.currency_symbol;
    this.baseOutstanding = this.outstanding =
      this.currentCase.d_outstanding.replace(
        this.currentCase.currency_symbol,
        ''
      );

    if (this.currentCase.arranagement) {
      this.currArrangement = this.currentCase.arranagement.current_arrangement;
    }
    if (this.currentCase.debtorid) {
      this.debtorId = this.currentCase.debtorid;
    }

    if (this.currArrangement)
      this.currArrangement = this.currArrangement.find(
        (data) => data.active == 1
      );

    this.makeCurrentArrangementString();
    if (this.currArrangement) {
      this.activeArrangements.scheduleArrangements.data =
        this.currentCase.arranagement.arrangement_schedule.reverse();
      this.activeArrangements.scheduleArrangements.data.forEach(
        (element, index) => {
          this.activeArrangements.scheduleArrangements.data[index].date =
            moment(element.date).format(this.dateFormat);
          let amount_paid;
          if (element.arr_balance == 0.0 && element.payment_action.id == -16) {
            amount_paid = element.amount;
          } else if (
            (element.arr_balance > 0.0 && element.payment_action.id == -12) ||
            (element.arr_balance < 0.0 && element.payment_action.id == -8)
          ) {
            amount_paid = element.amount + element.arr_balance;
          } else {
            amount_paid = element.arr_balance;
          }
          this.activeArrangements.scheduleArrangements.data[index].arr_balance =
            amount_paid;
        }
      );
    }
  }
  makeCurrentArrangementString() {
    this.currentArrangementString = '';

    let debtor_field = 'defendant';
    let currency_symbol = '&pound;';
    try {
      if (this.currentCase.scheme.scheme_manager.debtor_field) {
        debtor_field = this.currentCase.scheme.scheme_manager.debtor_field;
      }
      currency_symbol = this.currentCase.currency_symbol;
    } catch (error) {}
    if (this.currArrangement) {
      this.currentArrangementString += `The ${debtor_field} agreed to pay `;
      if (
        parseInt(this.currArrangement.first_amount, 10) > 0 &&
        this.currArrangement.first_date
      ) {
        this.currentArrangementString += `initial payment of <strong>${currency_symbol}${
          this.currArrangement.first_amount
        }
        </strong> due on <strong>${moment(
          this.currArrangement.first_date
        ).format(this.dateFormat)}</strong> followed by `;
      }
      this.currentArrangementString += `<strong>${currency_symbol}${
        this.currArrangement.amount
      }</strong> with a <strong>
      ${this.frequencies[this.currArrangement.freq]}</strong> on <strong>
      ${moment(this.currArrangement.start).format(
        this.dateFormat
      )}</strong>.</p>`;
      // if (this.isGroupArrangement) {
      //   this.currentArrangementString += `<div>Selected Cases:  ${JSON.parse(
      //     this.currArrangement.caseids
      //   ).join()}</div>`;
      // }
    } else {
      this.currentArrangementString += `The case ${this.caseId} has no arrangement set.`;
    }
  }
}
