import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IonNav, ModalController, AlertController } from '@ionic/angular';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { CardService } from 'src/app/services/card.service';
import { CaseService } from 'src/app/services/case.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-trxservices-card',
  templateUrl: './trxservices-card.page.html',
  styleUrls: ['./trxservices-card.page.scss'],
})
export class TrxservicesCardPage implements OnInit {
  @Input() caseData;
  @Input() planType = 'plan';
  @Input() selectedPlanData;
  caseId;
  @Input() debtorId = localStorage.getItem('debtor_id');
  selectedCases = [];
  debtorDetails = this.authService.debtorDetails;
  @Input() paymentMethod;
  addCardForm: FormGroup;
  datemin = moment().format('YYYY-MM-DD');
  datemax = moment().add('100', 'years').format('YYYY-MM-DD');
  years: number[] = this.generateYearList();
  months: number[] = this.generateMonthList();
  savedCardList = [];
  debtorDetailsSubscription: Subscription;
  caseDetailsSubscription: Subscription;
  paymentGatewayList = [];
  savedCards = [];
  isGroupArrangement = false;
  caseList = [];
  groupArrId;
  billingAddress = [];
  selectedBillingAddress;
  saveBtnDisabled = false;
  surcharge_amount = '';
  confirm_message = '';
  paymentFrequencyOption = '';

  amount; //add
  frequency; //add
  firstPaymentDate; //date
  paymentMethods = [
    { id: 1, label: 'Cash' },
    { id: 2, label: 'Cheque' },
    { id: 4, label: 'Credit card', name: 'card' },
    { id: 5, label: 'Debit Card', name: 'bank' },
    { id: 8, label: 'BACS' },
  ];
  method = null;
  outstanding;
  showOption:boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private cardService: CardService,
    private caseService: CaseService,
    private modalController: ModalController,
    private authService: AuthService,
    private ionNav: IonNav,
    private modalCtrl: ModalController,
    private alertController: AlertController
  ) {}

  ngOnInit() {
    const paymentData = this.caseService.paymentData;
    this.paymentMethod = paymentData.paymentMethod;
    this.amount = paymentData.amount;
    this.frequency = paymentData.freq;
    this.paymentFrequencyOption = paymentData.paymentFrequencyOption; //need in confirmPayment()
    this.firstPaymentDate = paymentData.date;
    this.outstanding = paymentData.total;
    this.caseList = paymentData.caseList;
    if (this.planType == 'group_plan') {
      this.caseId = this.caseData[0].id;
    } else {
      this.caseId = paymentData.caseId;
    }
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    //calculate service fee for show in pop up message
    const data = {
      amount: this.amount,
      type: 'card',
    };
    this.caseService
      .calculateTrxServiceFee(this.caseId, data)
      .subscribe((res: any) => {
        this.saveBtnDisabled = false;
        if (res.result) {
          this.surcharge_amount = res.result.data.surcharge_amount.toFixed(2);
          this.confirm_message = res.result.data.confirmMessage; //variable updated
        }
      });

    if (
      portalSettings.payment_method_list &&
      Object.values(portalSettings.payment_method_list)
    ) {
      this.paymentMethods = [];
      const payment_method_list = portalSettings.payment_method_list;
      Object.keys(payment_method_list).forEach((key) => {
        this.paymentMethods.push({
          id: parseInt(key),
          label: payment_method_list[key],
        });
      });
    }
    this.method = this.paymentMethods[0].id
      ? this.paymentMethods[0].id.toString()
      : '';
    this.initForm();
  }

  generateYearList(): number[] {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 16 }, (_, index) => currentYear + index);
    return years;
  }

  generateMonthList(): number[] {
    return Array.from({ length: 12 }, (_, index) => index + 1);
  }

  initForm() {
    this.addCardForm = this.formBuilder.group({
      card_number: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{12,20}$')],
      ],
      expiry_month: ['', [Validators.required]],
      expiry_year: ['', [Validators.required]],
      cvv: ['', [Validators.required]],
      debtor_name: [
        this.debtorDetails?.debtor_name ? this.debtorDetails.debtor_name : '',
        [Validators.required],
      ],
      description: [''],
      address_ln1: [
        this.debtorDetails?.addresses[0]?.address_ln1
          ? this.debtorDetails.addresses[0]?.address_ln1
          : '',
        [Validators.required],
      ],
      address_ln2: [
        this.debtorDetails?.addresses[0]?.address_ln2
          ? this.debtorDetails.addresses[0]?.address_ln2
          : '',
        [],
      ],
      address_town: [
        this.debtorDetails?.addresses[0]?.address_town
          ? this.debtorDetails.addresses[0]?.address_town
          : '',
        [],
      ],
      address_ln3: [
        this.debtorDetails?.addresses[0]?.address_ln3
          ? this.debtorDetails.addresses[0]?.address_ln3
          : '',
        [],
      ],
      address_postcode: [
        this.debtorDetails?.addresses[0]?.address_postcode
          ? this.debtorDetails.addresses[0]?.address_postcode
          : '',
        [Validators.required],
      ],
      //Add debtor email for form
      debtor_email: [
        this.debtorDetails?.debtor_email ? this.debtorDetails.debtor_email : '',
        [Validators.required],
      ],
    });
  }

  async save() {
    this.addCardForm.markAllAsTouched();
    if (this.addCardForm.valid) {
      /*@see LU-171 for showing payment confirmation pop up */
      if (this.confirm_message) {
        const alert = await this.alertController.create({
          header: 'There is a convenience fee per payment in the amount of ' + this.surcharge_amount + ' and Please confirm your agreement to this fee on each payment',
          message: `<ion-item>
                    <ion-label position="stacked">Your Name</ion-label>
                    <ion-input name="name" type="text" placeholder="Enter your name..." required></ion-input>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-checkbox name="agreement" slot="start" value="agree"></ion-checkbox>
                    <ion-label>Agreed</ion-label>
                  </ion-item>`,
          cssClass: 'custom-alert-class',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {},
            },
            {
              text: 'OK',
              handler: (data) => {
                const nameInput = document.querySelector('ion-input[name="name"]') as HTMLIonInputElement;
                const agreementCheckbox = document.querySelector('ion-checkbox[name="agreement"]') as HTMLIonCheckboxElement;
                const name = nameInput?.value;
                const agreement = agreementCheckbox?.checked;
                if (name && agreement) {
                  this.confirmPayment();
                } else {
                  if (!name) {
                    this.showAlert('Please Enter name.');
                  } else if (!agreement) {
                    this.showAlert('Please agree to the terms and conditions.');
                  }
                  return false; // Prevents the alert from closing
                }
              },
            },
          ],
        });
        await alert.present();
      } else {
        this.confirmPayment();
      }
    }
  }

  //show alert if name of aggrement checkbox is not checked
  async showAlert(message: string) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: message,
      buttons: ['OK'],
    });
    await alert.present();
  }

  //payment confirmation
  confirmPayment() {
    /** start date update when payment frequency option is month */
    var start = '';
    if (this.paymentFrequencyOption == 'month') {
      start = moment(this.firstPaymentDate).format('YYYY-MM-DD');
    } else{
      start = this.firstPaymentDate.split('T')[0];
    }
    this.commonService.showLoader();
    const trxservicesobj = {
      amount: this.amount ? this.amount : this.outstanding,
      freq: this.frequency,
      start: start,
      ref_amount: this.outstanding,
      surcharge_amount: this.surcharge_amount,
      debtor_name: this.addCardForm.value.debtor_name,
      debtor_email: this.addCardForm.value.debtor_email,
      card_number: this.addCardForm.value.card_number,
      cvv: this.addCardForm.value.cvv,
      expiry_month: this.addCardForm.value.expiry_month,
      expiry_year: this.addCardForm.value.expiry_year,
      address_ln1: this.addCardForm.value.address_ln1,
      address_ln2: this.addCardForm.value.address_ln2,
      address_ln3: this.addCardForm.value.address_ln3,
      town: this.addCardForm.value.address_town,
      address_postcode: this.addCardForm.value.address_postcode,
      debtor_id: this.debtorDetails.debtor_id,
      mode: 'make',
      payment_method: 'trxServices',
      repeatPayment: this.addCardForm.value.repeatPayment,
      method: this.addCardForm.value.method,
      is_group: this.planType == 'group_plan' ? 1 : 0,
      cases: this.caseList,
    };
    this.caseService
      .newRecurringCardPaymentPlan(this.caseId, trxservicesobj)
      .subscribe((res: any) => {
        this.saveBtnDisabled = false;
        this.commonService.dismissLoader();
        //handle API response
        if (res.data.success) {
          this.caseService.saveCaseDetails(localStorage.getItem('debtor_id'));
          this.ionNav.popToRoot();
          this.commonService.showToast(
            'Arrangement created Successfully!',
            'success'
          );
          this.modalCtrl.dismiss({
            saved: true,
          });
        } else {
          this.commonService.showToast(res.data.message, 'danger');
        }
      });
  }

  toggleOptions(value) {
    this.showOption = (value == false) ? true : false;
  }
}
