import { Component, Input, OnInit } from '@angular/core';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';
import { AuthService } from 'src/app/services/auth.service';
import { USAEPAYCardPage } from '../../payment-gateway/recurring/usaepay/usaepay-card/usaepay-card.page';
import { USAEPAYAchPage } from '../../payment-gateway/recurring/usaepay/usaepay-ach/usaepay-ach.page';;
import { BankPaymentPage } from '../bank-payment/bank-payment.page';
import { GoCardlessPage } from '../../payment-gateway/non-recurring/gocardless/gocardless.page';
import { TrxservicesCardPage } from '../../payment-gateway/recurring/trxservices/trxservices-card/trxservices-card.page';
import { TrxservicesAchPage } from '../../payment-gateway/recurring/trxservices/trxservices-ach/trxservices-ach.page';

import { IonNav } from '@ionic/angular';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.page.html',
  styleUrls: ['./payment-method.page.scss'],
})
export class PaymentMethodPage implements OnInit {
  @Input() caseData;
  @Input() debtorDetails;
  @Input() planType;
  @Input() selectedPlanData;
  selectedGateway;
  isConfigured = false;
  configuredRecurringPaymentMethod = ['usaepay','gocardless','trxServices'];
  paymentMethod = [
    {
      title: 'Card Payment',
      type: 'card',
      icon: 'card-outline',
      src: null,
    },
    {
      title: 'ACH Payment',
      type: 'ach',
      icon: 'card-outline',
      src: 'assets/icon/ach2.svg',
    },
    {
      title: 'Bank payment',
      type: 'bank',
      icon: 'card-outline',
      src: 'assets/icon/bank2.svg',
    },
  ];

  constructor(
    private caseService: CaseService,
    private commonService: CommonService,
    private authService: AuthService,
    private ionNav: IonNav
  ) {}

  ngOnInit() {
    let payment_type = {};
    this.selectedGateway = this.caseService.paymentData.selectedGateway;
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if (portalSettings?.payment_type) {
      payment_type = portalSettings?.payment_type
        ? portalSettings?.payment_type
        : {};
    }
    if (!this.configuredRecurringPaymentMethod.includes(this.selectedGateway)) {
      return;
    }
    this.isConfigured = true;
    const url = new URL(localStorage.getItem('server_url'));
    const subdomain = url.hostname.split('.').shift();
    const isC3Client = subdomain === 'c3';
    this.paymentMethod = this.paymentMethod.filter((method) => {
      if (method.type === 'ach') {
        return this.selectedGateway;
      }
      if (method.type === 'bank') {
        // return isC3Client;
        return this.selectedGateway === 'gocardless';
      }
      return true;
    });
    this.paymentMethod = this.filterPaymentMethods(this.selectedGateway, portalSettings, this.paymentMethod);
  }

  handleMethodClick(type) {
    if (type === 'card') {
      this.caseService.setPaymentData({ paymentMethod: type });
      if(this.selectedGateway == 'trxServices'){
        this.ionNav.push(TrxservicesCardPage, {
                caseData: this.caseData,
                planType: this.planType,
                selectedPlanData: this.selectedPlanData,
              });
      } else if(this.selectedGateway == 'usaepay') {
      this.ionNav.push(USAEPAYCardPage, {
        caseData: this.caseData,
        planType: this.planType,
        selectedPlanData: this.selectedPlanData,
      });
    }
    } else if (type == 'ach') {
      this.caseService.setPaymentData({ paymentMethod: type });
      if(this.selectedGateway == 'trxServices'){
        this.ionNav.push(TrxservicesAchPage, {
                caseData: this.caseData,
                planType: this.planType,
                selectedPlanData: this.selectedPlanData,
              });
      } else if(this.selectedGateway == 'usaepay') {
      this.ionNav.push(USAEPAYAchPage, {
        caseData: this.caseData,
        planType: this.planType,
        selectedPlanData: this.selectedPlanData,
      });
    }
    } else {
      this.caseService.setPaymentData({ paymentMethod: type });
      this.ionNav.push(GoCardlessPage, {
        caseData: this.caseData,
        debtorDetails: this.authService.debtorDetails,
        planType: this.planType,
        selectedPlanData: this.selectedPlanData,
      });
    }
  }
  filterPaymentMethods(selectedGateway, portalSettings, paymentMethods) {
    if(selectedGateway && portalSettings?.payment_type?.[selectedGateway]?.options.length) {
        const filteredMethods = paymentMethods.filter(method => {
            return portalSettings.payment_type[selectedGateway].options.includes(method.type);
        });
        return filteredMethods;
    } else {
        return paymentMethods;
    }
  }
}
