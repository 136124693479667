import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class CaseService {
  caseDetails;
  caseDetailsSB = new BehaviorSubject(null);
  caseList = [];
  paymentGatewayList = [];
  firstCaseId;
  private caseDetails$ = this.caseDetailsSB.asObservable();
  ionNav = null;
  paymentData = {
    paymentGatewayList: [],
    caseId: null,
    caseData: null,
    fromHome: true,
    paymentOption: null,
    paymentFrequencyOption: null,
    paymentFrequency: null,
    selectedGateway: null,
    dateType: null,
    date: null,
    text: null,
    amount: null,
    freq: null,
    customPlan: null,
    paymentMethod: null,
    address: null,
    scheduledAmount: null,
    limit: null,
    total: 0,
    caseList:[],
  };
  constructor(private http: HttpClient, private commonService: CommonService) {}


  /** @see LU-309 show Tax payer key in Accounr Details page for AFCS
   * @param string client
   * @return boolean
  */
  isClient(client: string): boolean {
    const url = new URL(localStorage.getItem('server_url'));
    const subDomain  = url.hostname.split('.').shift();
    return subDomain === client;
  }

  /**
   * Allow only group arrangement when multiple account link
   * @param boolean isGroupArrangement
   * @returns boolean
   */
  allowOnlyGroupArrangement(isGroupArrangement: boolean): boolean {
    return (isGroupArrangement) && (this.caseList.length > 1);
  }

  getCaseDetails(debtor_id) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/get_case_details?debtor_id=${debtor_id}&source=debtor_API`;
    return this.http.get(apiURL);
  }

  saveCaseDetails(debtor_id) {
    this.commonService.showLoader('Loading ...', 2000);
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/get_case_details?debtor_id=${debtor_id}&source=debtor_API`;
    this.http.get(apiURL).subscribe((res: any) => {
      if (res && res.result) {
        this.caseDetails = res.data;
        this.caseList = Object.values(res.data.caseList);
        if (this.caseList && this.isClient('afcs')) {
          let anyCustom5Invalid = this.caseList.some(item => item.custom5 == null || item.custom5 === '');
          if (!anyCustom5Invalid) {        
            this.caseList.sort((a, b) => a.custom5 - b.custom5);
          }else{
            this.caseList.sort((a, b) => a.date - b.date);
          }
        }

        // CORE1-3015 Added check for custom conditions for showing payment plan
        let settings = JSON.parse(localStorage.getItem('portal_settings'));4
        let conditions = {};
        if(settings.arrangement?.custom_conditions) {
          conditions = settings.arrangement.custom_conditions;
        }
        let arr_option = settings?.arr_option
        ? settings?.arr_option
        : { request: true, perform: false };
        this.caseList.forEach(caseDetails => {
          caseDetails.paymentPlanRequest = this.isPaymentPlanRequest(caseDetails, conditions, arr_option);
          caseDetails.paymentPlanPerform = this.isPaymentPlanPerform(caseDetails, conditions, arr_option);
        });
        this.paymentGatewayList = res.data.paymentGatewayList;
        this.setPaymentData({
          paymentGatewayList: this.paymentGatewayList,
        });
        localStorage.setItem('dateFormat', res.data.dateFormat);
        this.firstCaseId = this.caseList[0]?.['id'];
      }
      this.caseDetailsSB.next(res);
      this.commonService.dismissLoader();
    });
  }

  getCaseDetailsData() {
    return this.caseDetails$;
  }

  setMyNav(ionNav) {
    this.ionNav = ionNav;
  }

  getMyNav() {
    return this.ionNav;
  }

  setPaymentData(data) {
    this.paymentData = { ...this.paymentData, ...data };
  }

  getPaymentData() {
    return this.paymentData;
  }

  getDebtorDetails(debtor_id) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/get_debtor_details?debtor_id=${debtor_id}&source=debtor_API`;
    return this.http.get(apiURL);
  }

  getCaseCustomVariables(case_id, regenerate = false) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/get_case_custom_variables?case_id=${case_id}&regenerate=${regenerate}&source=debtor_API`;
    return this.http.get(apiURL);
  }

  getCaseVariables(case_id, regenerate = false) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/get_case_variables?case_id=${case_id}&regenerate=${regenerate}&source=debtor_API`;
    return this.http.get(apiURL);
  }

  paymentPlan(data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/payment_plan?source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  contactForm(data) {
    let formData = new FormData();
    formData.append('note',data.note);
    formData.append('caseid',data.caseid);
    if(data.type){
      formData.append('type',data.type);
    }
    if(data.attachments.length>0){
        data.attachments.forEach(attachment => {
        formData.append('attachments[]',attachment);
       });
    }
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/contact_form?source=debtor_API`;
    return this.http.post(apiURL, formData);
  }

  getGovUKPayLink(data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/govuk_pay_link?source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  getMobipaidLink(data){
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/mobipaid_link?source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makeUSAePayment(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/usa_epay_payment?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makeUSAePaymentRequest(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/usa_epay_payment_request?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makePayrocPaymentRequest(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/payroc_payment_request?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makePayRocPayment(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/payroc_payment?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makeTrxServicesPaymentRequest(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/trx_services_payment_request?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makeTrxServicesPayment(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/trx_services_payment?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  createArrangement(type, caseId, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/clickdebt_panel_layout/arrangements/case_actions_panels/${type}/${caseId}?source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  previewArrangementPlan(caseId, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/clickdebt_panel_layout/arrangements/case_actions_panels/preview/${caseId}?source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  newRecurringCardPaymentPlan(caseId, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/clickdebt_panel_layout/payment/case_actions_panels/case_action_recurring_payment/${caseId}?source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  newRecurringACHPaymentPlan(caseId, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/clickdebt_panel_layout/payment/case_actions_panels/case_action_recurring_ach_payment/${caseId}?source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makeStripePaymentRequest(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/stripe_payment_request?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makeStripePayment(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/stripe_payment?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  addStripeCustomer(data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/clickdebt_panel_layout/payment/case_actions_panels/add_stripe_customer/`;
    return this.http.post(apiURL, data);
  }

  makeUSAeAchRequest(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/usa_epay_ach_payment?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  addBankAccount(debtor_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/add_bank_account?debtor_id=${debtor_id}&source=debtor_API`;
      return this.http.post(apiURL, data);
  }

  makeGocardlessPaymentRequest(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/gocardless_payment_request?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makeGoCardlessPayment(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/gocardless_payment?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makeAcquiredPaymentRequest(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/acquired_payment_request?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makeAcquiredPayment(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/acquired_payment?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  maketrxAchRequest(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/trx_ach_payment?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  calculateTrxServiceFee(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/calculate_service_fee??case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  getCaseDocuments(caseId) {
    const apiURL = localStorage.getItem('server_url') + `b/clickdebt_ajax_layout/legacy/panels/case_documents/${caseId}?source=debtor_API`;
    return this.http.get(apiURL);
  }
  uploadDocument(file, caseId, fileName = '', doc_type = '') {
    let formData = new FormData();
    if(doc_type){
      formData.append('document_category',doc_type);
    }
    if(fileName) {
      formData.append('file', file, fileName);
      formData.append('doc_name',fileName);
    } else {
      formData.append('file', file);
    }
    const apiURL = localStorage.getItem('server_url') + `b/clickdebt_ajax_layout/legacy/panels/upload_case_documents/${caseId}?source=debtor_API`;
    return this.http.post(apiURL, formData);
  }
  deleteDocument(documentId){
    const apiURL=localStorage.getItem('server_url') +`b/clickdebt_panel_layout/legacy/documents/delete_document/${documentId}?source=debtor_API`;
    return this.http.delete(apiURL);
  }

  reverifyGatewayPaymentFee($data) {
    const apiURL=localStorage.getItem('server_url') +`b/system/v3/debtor_api/reverify_gateway_payment_fee?source=debtor_API`;
    return this.http.post(apiURL, $data);
  }

  getGroupArrangement(data){
    const apiURL =
    localStorage.getItem('server_url') +
    `b/system/v3/debtor_api/get_group_case_arrangement?source=debtor_API`;
  return this.http.post(apiURL, data);
  }

  communicationPreferences(caseId, data){
    const apiURL =
    localStorage.getItem('server_url') +
    `b/system/v3/debtor_api/communication_preferences?case_id=${caseId}&source=debtor_API`;
  return this.http.post(apiURL, data);
  }

  /**
   * CORE1-3015 for evaluating condition for display rules
   * @param query display rules for payment plan option
   * @param caseDetails
   * @returns
   */
  checkConditions(query, caseDetails) {
    // Define helper to evaluate a condition
    const evaluateCondition = (operator, caseValue, queryValues) => {
        // If queryValues is undefined or empty, skip this condition
        if (!queryValues || queryValues.length === 0) {
            return null; // No specific condition to meet, ignore in calculations
        }
        return queryValues.includes(caseValue);
    };

    // Evaluate each condition, accounting for empty arrays
    const statusCondition = evaluateCondition(query.operator, caseDetails.current_status_id, query.statuses_id);
    const schemeCondition = evaluateCondition(query.operator, caseDetails.scheme_id, query.schemes_id);
    const stageCondition = evaluateCondition(query.operator, caseDetails.current_stage_id, query.stages_id);
    const clientCondition = evaluateCondition(query.operator, caseDetails.client_id, query.clients_id);

    // Collect valid conditions (exclude nulls from empty conditions)
    const conditions = [statusCondition, schemeCondition, stageCondition, clientCondition].filter(cond => cond !== null);

    // Apply logic based on operator and conditionMatchType
    if (query.operator === "!=" && query.conditionMatchType === "or") {
        // For != with 'or': Return false if any condition is true (meaning one value matches), else return true
        return !conditions.some(cond => cond === true);
    } else if (query.operator === "=" && query.conditionMatchType === "or") {
        // For = with 'or': Return true if any condition is true (meaning one value matches)
        return conditions.some(cond => cond === true);
    } else if (query.operator === "!=" && query.conditionMatchType === "and") {
        // For != with 'and': Return false if all conditions are true (all values match), else return true
        return !conditions.every(cond => cond === true);
    } else if (query.operator === "=" && query.conditionMatchType === "and") {
        // For = with 'and': Return true only if all conditions are true (all values match)
        return conditions.every(cond => cond === true);
    }

    return false; // Default return for unsupported cases
  }

  isPaymentPlanPerform(caseDetail, custom_conditions, arr_option){
    let paymentPlanPerform = arr_option.perform;
    if(paymentPlanPerform) {
      console.log(caseDetail);
      if(custom_conditions.apply_on_create) {
        paymentPlanPerform = this.checkConditions(custom_conditions, caseDetail)
      }
    }
    return paymentPlanPerform;
  }

  isPaymentPlanRequest(caseDetail, custom_conditions, arr_option){
    let paymentPlanRequest = arr_option.request;
    if(paymentPlanRequest) {
      if(custom_conditions.apply_on_request) {
        paymentPlanRequest = this.checkConditions(custom_conditions, caseDetail)
      }
    }
    return paymentPlanRequest;
  }
}
