import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import {
  AlertController,
  ModalController,
  Platform,
  PopoverController,
} from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './terms.page.html',
  styleUrls: ['./terms.page.scss'],
})
export class TermsPage implements OnInit {
  message: string = '';
  terms: string = '';
  url: string = '';
  sanitizedHtml: SafeHtml;
  sanitizedTerms: SafeHtml;
  isTermsExpanded: boolean = false;
  isAgreed: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router: Router,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    if (localStorage.getItem('portal_settings')) {
      let settings = JSON.parse(localStorage.getItem('portal_settings'));

      if (settings?.additional_settings?.custom_notifications?.after_login) {
        const notificationMessage =
          settings.additional_settings.custom_notifications.after_login;
        const termsText =
          settings.additional_settings.custom_notifications.terms_conditions;

        this.message = notificationMessage;
        this.terms = termsText;
        this.url = this.route.snapshot.queryParams.url;

        if (this.message) {
          this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(
            this.message
          );
        }
        if (this.terms) {
          this.sanitizedTerms = this.sanitizer.bypassSecurityTrustHtml(
            this.terms
          );
        } else {
          this.isAgreed = true;
        }
      }
    }
  }

  toggleTerms() {
    this.isTermsExpanded = !this.isTermsExpanded;
  }

  onSubmit() {
    if (this.isAgreed) {
      localStorage.setItem('remote_token', this.authService.remote_token);
      this.router.navigateByUrl('.' + this.url);
    }
  }
}
