import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IonNav, ModalController } from '@ionic/angular';
import { AddressDetailsPage } from 'src/app/master/address-details/address-details.page';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';
declare var usaepay: any;
@Component({
  selector: 'app-usaepay-card',
  templateUrl: './usaepay-card.page.html',
  styleUrls: ['./usaepay-card.page.scss'],
})
export class USAepayCardPage implements OnInit {
  @Input() caseId;
  @Input() responseData;
  @Input() debtorDetails;
  @Input() totalOutstandingWithSymbol;
  @Input() caseDetails;
  title = 'Lateral Payment Portal';
  USAePayForm: FormGroup;
  saveBtnDisabled = false;
  usaepayclient;
  paymentCard;
  usaepay: any;
  jsUrl = '';
  publickey = '';
  isConfigured = true;
  amount:any = 0;
  surcharge_amount;
  fieldLabels: any = {};
  loadedTemplate = false;
  paymentGatewayTemplate;
  constructor(
    private formBuilder: FormBuilder,
    private caseService: CaseService,
    private commonService: CommonService,
    private ionNav: IonNav,
    private modalController: ModalController
  ) {}

  loadScript(url: string) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }

  ngOnInit() {
    this.saveBtnDisabled = true;
    this.amount = parseFloat(this.responseData?.net_amount).toFixed(2);
    this.surcharge_amount = this.responseData?.surcharge_amount;
    this.jsUrl = 'https://sandbox.usaepay.com/js/v1/pay.js';
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if (portalSettings?.payment_type) {
      let payment_type = portalSettings?.payment_type
        ? portalSettings?.payment_type
        : {};
      if (payment_type?.usaepay && payment_type?.usaepay?.label) {
        this.title = payment_type.usaepay.label;
      }
    }
    if (portalSettings?.fieldLabels) {
      this.fieldLabels = portalSettings.fieldLabels;
    }
    if (
      portalSettings?.payment_gateway_details?.usaepay?.jsUrl &&
      portalSettings?.payment_gateway_details.usaepay?.publickey
    ) {
      this.jsUrl = portalSettings.payment_gateway_details.usaepay.jsUrl;
      this.publickey = portalSettings.payment_gateway_details.usaepay.publickey;
      this.isConfigured = true;
    }
    if(portalSettings?.gateway_templates?.usaepay?.card_non_recurring_payment){
      this.paymentGatewayTemplate=portalSettings.gateway_templates.usaepay.card_non_recurring_payment;
    }
    this.loadScript(this.jsUrl)
      .then(() => {
        this.usaepayclient = new usaepay.Client(this.publickey);
        this.paymentCard = this.usaepayclient.createPaymentCardEntry();
        this.paymentCard.generateHTML();
        this.paymentCard.addHTML('paymentCardContainer');
        setTimeout(() => {
          this.loadedTemplate = true;
        }, 1000);
        this.paymentCard.addEventListener('error', (errorMessage) => {
          var errorContainer = document.getElementById(
            'paymentCardErrorContainer'
          );
          errorContainer.textContent = errorMessage;
        });
        setTimeout(() => (this.saveBtnDisabled = false), 3000);
      })
      .catch((error) => {
        console.error(error);
      });

    this.initForm();
  }

  initForm() {
    const addresses = this.debtorDetails?.addresses.filter(
      (add) => add.address_main == 1
    ) || [{}];
    this.USAePayForm = this.formBuilder.group({
      amount: [{value: this.amount ? this.amount : 0, disabled: true}, [Validators.required]],
      debtor_first_name: [
        this.debtorDetails?.debtor_1_name
          ? this.debtorDetails?.debtor_1_name
          : '',
        [Validators.required],
      ],
      debtor_last_name: [
        this.debtorDetails?.debtor_1_surname
          ? this.debtorDetails?.debtor_1_surname
          : '',
        [Validators.required],
      ],
      description: [''],
      address_ln1: [
        addresses[0]?.address_ln1 ? addresses[0]?.address_ln1 : '',
        [Validators.required],
      ],
      address_ln2: [
        addresses[0]?.address_ln2 ? addresses[0]?.address_ln2 : '',
        [],
      ],
      address_ln3: [
        addresses[0]?.address_ln3 ? addresses[0]?.address_ln3 : '',
        [],
      ],
      address_town: [
        addresses[0]?.address_town ? addresses[0]?.address_town : '',
        [Validators.required],
      ],
      address_postcode: [
        addresses[0]?.address_postcode ? addresses[0]?.address_postcode : '',
        [Validators.required],
      ],
    });
  }

  async generatePaymentKey() {
    var errorContainer = document.getElementById('paymentCardErrorContainer');
    this.saveBtnDisabled = true;
    await this.commonService.showLoader();
    try {
      const result = await this.usaepayclient.getPaymentKey(this.paymentCard);
      if (result.error) {
        errorContainer.textContent = result.error.message;
        errorContainer.classList.remove('d-none');
        this.saveBtnDisabled = false;
        this.commonService.dismissLoader();
      } else {
        this.proceedPayment(result);
      }
    } catch (error) {
      this.saveBtnDisabled = false;
      this.commonService.dismissLoader();
      this.commonService.showToast(error, 'danger');
    }
  }

  proceedPayment(paymentKey) {
    if (this.USAePayForm.valid) {
      this.saveBtnDisabled = true;
      this.USAePayForm.get('amount')?.enable();
      let obj = this.USAePayForm.value;
      obj['payment_key'] = paymentKey;
      obj['gateway_id'] = this.responseData.id;
      obj['debtor_id'] = this.debtorDetails.debtor_id;
      if(obj['address_town']){
        obj['town'] = obj['address_town'];
      }
      if(this?.caseDetails?.acceptSettlementOffer) {
        obj['acceptSettlementOffer'] = this?.caseDetails?.acceptSettlementOffer;
        obj['settlementAmount'] = this?.caseDetails?.settlementAmount;
      }
      this.caseService
        .makeUSAePayment(this.caseId, obj)
        .subscribe((res: any) => {
          this.saveBtnDisabled = false;
          this.commonService.dismissLoader();
          if (res.result) {
            let toast_type = (res['data']['result']) ? 'success' : 'danger';
            this.commonService.showToast(res['data']['message'], toast_type);
            this.caseService.saveCaseDetails(localStorage.getItem('debtor_id'));
            this.ionNav.popToRoot();
          } else {
            this.commonService.showToast(res['message'], 'danger');
            this.dismiss();
          }
        });
    }
  }

  async dismiss() {
    this.ionNav.pop();
  }

  cancel() {
    this.dismiss();
  }

  async changeAddress() {
    const values = this.USAePayForm.value;
    const address = {
      address_ln1: values.address_ln1,
      address_ln2: values.address_ln2,
      address_ln3: values.address_ln3,
      address_town: values.address_town,
      address_postcode: values.address_postcode,
    };
    const requiredAddress = {
      address_ln1: true,
      address_town: true,
      address_postcode: true,
    };
    const modal = await this.modalController.create({
      component: AddressDetailsPage,
      componentProps: {
        address,
        requiredAddress,
        setAddress: (data) => {
          console.log(data);

          Object.keys(data).forEach((key) => {
            this.USAePayForm.controls[key].setValue(data[key]);
          });
          data['town'] = data['address_town'];
          data['payment_method'] = 'usaepay';
          data['amount'] = this.responseData.net_amount;
          data['surcharge_amount'] = this.responseData.surcharge_amount;
          this.caseService
            .reverifyGatewayPaymentFee(data)
            .subscribe((res : any) => {
              if(res.result) {
                this.amount = parseFloat(res.data.amount).toFixed(2);
                this.surcharge_amount = res.data.surcharge_amount;
                this.USAePayForm.controls['amount'].setValue(this.amount);
              }
            })
        },
      },
    });
    return await modal.present();
  }

  isAddressMissing() {
    const value = this.USAePayForm.value;
    if (
      !value['address_town'] ||
      !value['address_postcode'] ||
      !value['address_ln1']
    )
      return true;
    return false;
  }

  getBillingAddress(add) {
    const address_ln1 = add?.address_ln1 ? add?.address_ln1 + ', ' : '';
    const address_ln2 = add?.address_ln2 ? add?.address_ln2 + ', ' : '';
    const address_ln3 = add?.address_ln3 ? add?.address_ln3 + ', ' : '';
    const address_town = add?.address_town ? add?.address_town + ', ' : '';
    const address_postcode = add?.address_postcode ? add?.address_postcode : '';
    return (
      address_ln1 + address_ln2 + address_ln3 + address_town + address_postcode
    );
  }
}
