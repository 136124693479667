import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IonNav, ModalController } from '@ionic/angular';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';
import * as moment from 'moment';

@Component({
  selector: 'app-payment-plan-request',
  templateUrl: './payment-plan-request.page.html',
  styleUrls: ['./payment-plan-request.page.scss'],
})
export class PaymentPlanRequestPage implements OnInit {
  @Input() caseId;
  @Input() totalOutstanding;
  @Input() isGroupArrangement;
  @Input() debtorDetails;
  paymentPlanForm: FormGroup;
  saveBtnDisabled = false;
  selectOptions = {};
  frequency = [
    { id: 'Monthly by date', label: 'Monthly by date' },
    { id: 'One time final payment', label: 'One time final payment' },
    { id: 'Weekly By day of the week', label: 'Weekly By day of the week' },
    { id: 'Monthly by day', label: 'Monthly by day' },
    { id: 'Fortnightly', label: 'Fortnightly' },
    { id: 'four weekly', label: '4 weekly' },
    { id: 'Last day of the month', label: 'Last day of the month' },
    { id: 'First day of the month', label: 'First day of the month' },
    { id: 'Last work day of the month', label: 'Last work day of the month' },
    { id: 'Last Friday of the month', label: 'Last Friday of the month' },
  ];
  datepicker_date_format = 'DD/MM/YYYY';
  datemin = moment().format('YYYY-MM-DD');
  datemax = moment().add('100', 'years').format('YYYY-MM-DD');
  message = ''

  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private caseService: CaseService,
    private commonService: CommonService,
    private ionNav: IonNav
  ) {}

  ngOnInit() {
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    this.message = portalSettings.interest_message;
    this.initForm();
  }

  initForm() {
    this.paymentPlanForm = this.formBuilder.group({
      frequency: ['', [Validators.required]],
      amount: ['', [Validators.required]],
      additionalInfo: [''],
      phoneNumber: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      date: ['', [Validators.required]],
    });
    let phoneNumber =
      this.debtorDetails?.debtor_mobile != ''
        ? this.debtorDetails.debtor_mobile
        : this.debtorDetails?.debtor_phone != ''
        ? this.debtorDetails.debtor_phone
        : '';
    if (phoneNumber == '') {
      if (this.debtorDetails.phone_numbers.length > 0) {
        phoneNumber = this.debtorDetails.phone_numbers[0].number;
      }
    }
    let email =
      this.debtorDetails?.debtor_email != ''
        ? this.debtorDetails.debtor_email
        : '';
    if (email == '') {
      if (this.debtorDetails.emails.length > 0) {
        email = this.debtorDetails.emails[0].account;
      }
    }
    this.paymentPlanForm.controls.phoneNumber.setValue(phoneNumber);
    this.paymentPlanForm.controls.email.setValue(email);

    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if (portalSettings?.datepicker_date_format) {
      this.datepicker_date_format = portalSettings.datepicker_date_format;
    }
  }

  async save() {
    if (this.paymentPlanForm.valid) {
      this.saveBtnDisabled = true;
      let paymentPlan = {};
      if (this.paymentPlanForm.value.amount <= 0) {
        this.commonService.showToast('Entered amount should be greater than zero!', 'danger');
        this.saveBtnDisabled = false;
        return;
      }
      paymentPlan = {
        caseid: this.caseId,
        frequency: this.paymentPlanForm.value.frequency,
        amount: this.paymentPlanForm.value.amount,
        date: moment(this.paymentPlanForm.value.date).format(this.datepicker_date_format),
        phoneNumber: this.paymentPlanForm.value.phoneNumber,
        additionalInfo: this.paymentPlanForm.value.additionalInfo,
        email: this.paymentPlanForm.value.email,
        isGroupArrangement: this.isGroupArrangement,
      };
      this.caseService.paymentPlan(paymentPlan).subscribe((res: any) => {
        this.saveBtnDisabled = false;
        if (res.result) {
          this.commonService.showToast(res['data']['message'], 'success');
          this.ionNav.popToRoot();
        } else {
          this.commonService.showToast(res['message'], 'danger');
          this.dismiss();
        }
      });
    }
  }

  async dismiss() {
    this.ionNav.pop();
  }
  cancel() {
    this.dismiss();
  }
}
