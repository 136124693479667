import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AlertController, Platform, PopoverController } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { CommonService } from '../services/common.service';
import { ReinitService } from '../services/reinit.service';
import { Subscription } from 'rxjs';
import { HelpContentComponent } from './help-content/help-content.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  portalSettingsSubscription: Subscription;
  loginForm: FormGroup;
  dpa_check: string;
  dynamicLogin: number = 0;
  newField;
  fieldList = {
    case_ref: {
      key: 'case_ref',
      label: 'Case Ref',
      content: '',
    },
    surname: {
      key: 'surname',
      label: 'Surname',
      content: '',
    },
  };
  fieldKeys;
  isMobile = false;
  title = 'Lateral Debtor Portal';
  logo_url = '';
  background_url = '';
  client = '';
  loginBtnDisabled = false;
  datepicker_date_format = 'DD/MM/YYYY';
  showInfoIcon = false;
  login_footer_message: string;
  showNotification = false;

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private platform: Platform,
    private popoverController: PopoverController,
    private reinitService: ReinitService,
    private alertController: AlertController,
  ) {
    this.dpa_check = '';
    this.fieldKeys = Object.keys(this.fieldList);
    this.login_footer_message = '';
  }

  ngOnInit() {
    this.initForm();
  }
  ngOnDestroy(): void {
    if (this.portalSettingsSubscription) {
      this.portalSettingsSubscription.unsubscribe();
    }
  }

  ionViewWillEnter() {
    this.isMobile = this.platform.is('mobile');
    if (localStorage.getItem('dynamicLogin')) {
      this.dynamicLogin = parseInt(localStorage.getItem('dynamicLogin'));
      // localStorage.removeItem('dynamicLogin')
      localStorage.removeItem('default_webform_configured');
    }
    this.route.queryParams.subscribe((params) => {
      if (params && params.dynamic) {
        this.dynamicLogin = params.dynamic;
      }
    });
    if (this.router.url == '/viewmycase') {
      this.dynamicLogin = 1;
      localStorage.setItem('from_viewmycase', this.dynamicLogin.toString());
    } else {
      localStorage.removeItem('from_viewmycase');
    }
    this.fetchSettings();
  }

  initForm() {
    this.loginForm = this.formBuilder.group({
      dob: [
        '',
        Validators.pattern(
          '((0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/](?:19|20)[0-9]{2}$)|((0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/](?:19|20)[0-9]{2}$)'
        ),
      ],
      postcode: [''],
      surname: [''],
      name: [''],
      full_name: [''],  /** @see LU-182 give support of full name for debtor portal login */
      case_id: [''],
      case_ref: [''],
      mobile_no: [''],
      ssn: ['', Validators.pattern('[0-9]{4}')],
    });
    if (localStorage.getItem('dpa_check')) {
      this.dpa_check = localStorage.getItem('dpa_check');
    }
  }

  updateField(key = '') {
    if (this.dynamicLogin == 1) {
      const fields = {
        case_ref: {
          alt: 'case_id',
          key: 'case_ref',
          label: this.fieldList['case_ref']?.label  || 'Case Reference',
        },
        case_id: {
          alt: 'case_ref',
          key: 'case_id',
          label: this.fieldList['case_id']?.label ||'Case Id',
        },
      };
      if (!key) {
        if (this.fieldKeys.includes(fields['case_ref'].key)) {
          if (!this.fieldKeys.includes(fields['case_id'].key)) {
            this.newField = fields['case_id'];
          }
        } else if (this.fieldKeys.includes(fields['case_id'].key)) {
          if (!this.fieldKeys.includes(fields['case_ref'].key)) {
            this.newField = fields['case_ref'];
          }
        }
        return;
      }
      const toggleField = (field, altKey) => {
        if (!this.fieldKeys.includes(field.key)) {
          this.fieldList[field.key] = field;
          this.fieldKeys.push(field.key);
          this.fieldKeys = this.fieldKeys.filter((k) => k != altKey);
          this.newField = fields[altKey];
          this.loginForm.controls[altKey].setValue('');
        }
      };
      if (['case_id', 'case_ref'].includes(key)) {
        toggleField(fields[key], fields[key].alt);
      }
    }
  }

  login() {
    if (
      (this.dpa_check == 'dob' && this.loginForm.value.dob != '') ||
      (this.dpa_check == 'postcode' && this.loginForm.value.postcode != '')
    ) {
      console.log('login');
      this.loginBtnDisabled = true;
      this.commonService.showLoader('Loading', 2000);
      let debtor_id = localStorage.getItem('debtor_id');
      let form_slug = localStorage.getItem('form_slug');
      let dpa_pass_key;
      switch (this.dpa_check) {
        case 'dob':
          dpa_pass_key = this.loginForm.value.dob
            .split('/')
            .reverse()
            .join('-');
          if (this.datepicker_date_format == 'MM/DD/YYYY') {
            let dateSplit = this.loginForm.value.dob.split('/');
            dpa_pass_key =
              dateSplit[2] + '-' + dateSplit[0] + '-' + dateSplit[1];
          }
          break;
        case 'postcode':
          dpa_pass_key = this.loginForm.value.postcode;
          break;
      }
      this.authService
        .authenticate(debtor_id, form_slug, this.dpa_check, dpa_pass_key)
        .subscribe((res) => {
          this.commonService.dismissLoader();
          if (res['result']) {
            if (this.showNotification) {
              localStorage.setItem('is_login', '1');
            } else {
              localStorage.setItem('remote_token', res['data']);
            }
            this.authService.remote_token = res['data'];
            let navigationExtras: NavigationExtras = {
              queryParams: {
                fslug: localStorage.getItem('form_slug'),
                cid: localStorage.getItem('case_id'),
                did: localStorage.getItem('debtor_id'),
                dpa: localStorage.getItem('dpa_check'),
              },
              replaceUrl: true,
            };
            localStorage.setItem('dynamicLogin', this.dynamicLogin.toString());
            this.router.navigate(['/home'], navigationExtras);
          } else {
            this.commonService.showToast('Invalid Date of Birth', 'danger');
            this.commonService.showToast(res['message'], 'danger');
            this.loginBtnDisabled = false;
          }
        });
    }
  }

  addSlash(e) {
    if (e.which !== 8) {
      let length = this.loginForm.value.dob.length;
      if (length === 2 || length === 5) {
        let dob = this.loginForm.value.dob + '/';
        this.loginForm.controls['dob'].patchValue(dob);
      }
    }
  }

  login_direct() {
    let data = this.loginForm.value;
    let filtered_data = {};
    Object.keys(data).filter((value) =>
      data[value] != ''
        ? value == 'dob'
          ? (filtered_data[value] = data[value].split('/').reverse().join('-'))
          : (filtered_data[value] = data[value])
        : ''
    );
    if ('dob' in filtered_data) {
      let dateSplit = this.loginForm.value.dob.split('/');
      Object.assign(filtered_data, {
        dob: dateSplit[2] + '-' + dateSplit[0] + '-' + dateSplit[1],
      });
    }
    if (Object.keys(filtered_data).length >= 2) {
      console.log('login_direct');
      this.loginBtnDisabled = true;
      this.commonService.showLoader('Loading', 2000);
      this.authService.authenticate_direct(filtered_data).subscribe((res) => {
        this.commonService.dismissLoader();
        if (res['result']) {
          if (this.showNotification) {
            localStorage.setItem('is_login', '1');
          } else {
            localStorage.setItem('remote_token', res['data']['remote_token']);
          }
          this.authService.remote_token = res['data']['remote_token'];
          localStorage.setItem('case_id', res['data']['case_id']);
          this.authService.case_id = res['data']['case_id'];
          localStorage.setItem('debtor_id', res['data']['debtor_id']);
          this.authService.debtor_id = res['data']['debtor_id'];
          localStorage.setItem(
            'default_webform_configured',
            res['data']['default_webform_configured']
          );
          this.authService.default_webform_configured =
            res['data']['default_webform_configured'];
          localStorage.setItem('dynamicLogin', this.dynamicLogin.toString());
          this.authService.dynamicLogin = this.dynamicLogin.toString();
          this.reinitService.triggerReinit();
          if (res['data']['default_webform_configured']) {
            localStorage.setItem('form_slug', res['data']['form_slug']);
            this.authService.form_slug = res['data']['form_slug'];
            localStorage.setItem('dpa_check', res['data']['dpa_check']);
            this.authService.dpa_check = res['data']['dpa_check'];
            let navigationExtras: NavigationExtras = {
              queryParams: {
                fslug: res['data']['form_slug'],
                cid: res['data']['case_id'],
                did: res['data']['debtor_id'],
                dpa: res['data']['dpa_check'],
              },
              replaceUrl: true,
            };
            this.router.navigate(['/home'], navigationExtras);
          } else {
            this.router.navigate(['/main'], {
              replaceUrl: true, // Add this option.
            });
          }
        } else {
          this.loginBtnDisabled = false;
          this.commonService.showToast(res['message'], 'danger');
        }
      });
    }
  }

  fetchSettings() {
    this.authService.portalSettings2().subscribe((res: any) => {
      let portalSettings = res.data;
      Object.keys(portalSettings.dynamic_field).forEach(elementKey => {
        if(!portalSettings.dynamic_field[elementKey].label){
        portalSettings.dynamic_field[elementKey].label = this.toCamelCase(portalSettings.dynamic_field[elementKey].key);
        }
      });
      localStorage.setItem('portal_settings', JSON.stringify(portalSettings));
      if (this.dynamicLogin) {
        if (portalSettings.dynamic_field) {
          if (
            Object.keys(
              portalSettings?.dynamic_field ? portalSettings?.dynamic_field : {}
            ).length >= 2
          ) {
            this.fieldList = portalSettings.dynamic_field;
            this.fieldKeys = Object.keys(this.fieldList);
          }
          this.updateField();
        }
        // To show help icon only for dynamic login
        if(this.fieldKeys && this.fieldList){
          this.fieldKeys.forEach(key => {
            console.log(this.fieldKeys[key]?.content);
            if(this.fieldList[key]?.content != '') this.showInfoIcon = true
          });
        }
      }
      if (portalSettings?.portal_details?.title) {
        this.title = portalSettings.portal_details.title;
        var domain = new URL(localStorage.getItem('server_url')).hostname.split('.')[0];
        this.client = domain.charAt(0).toUpperCase() + domain.slice(1);
      }
      if (portalSettings?.portal_details?.logo_url) {
        this.logo_url = portalSettings.portal_details.logo_url;
      }
      if (portalSettings?.portal_details?.background_url) {
        this.background_url = portalSettings.portal_details.background_url;
      }
      if (portalSettings?.datepicker_date_format) {
        this.datepicker_date_format = portalSettings.datepicker_date_format;
      }
      if (portalSettings?.additional_settings?.custom_notifications?.show_notification_panel) {
        this.showNotification = portalSettings.additional_settings.custom_notifications.show_notification_panel;
      }
      //'Having trouble signing in' message. - LU-254
      this.login_footer_message = portalSettings?.portal_details?.login_footer_message;
    });
  }

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: HelpContentComponent,
      event: ev,
      translucent: true,
      componentProps: {
        fieldList: this.fieldList,
        fieldKeys: this.fieldKeys
      }
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
  }

  toCamelCase(str) {
    str = str.replace(/_/g, ' ');
    str = str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
        return word.toUpperCase();
    });
    str = str.charAt(0).toUpperCase() + str.slice(1);
    return str;
}

  async forgotLoginDetails(){
    const alert = await this.alertController.create({
        header: "Enter Details below",
        message: '',
        inputs: [
          { name: 'ssn',
            type: 'number',
            placeholder: 'Last Four Digits of SSN',
            attributes: {
              maxlength: 4,
              min: 1
            },
          },
          {
            name: 'surname',
            type: 'text',
            placeholder: 'Last Name',
          }
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              return ;
            },
          },
          {
            text: 'Continue',
            role: 'OK',
            handler:  async(data) => {
              await this.getForgotLoginDetails(data);
            },
          },
        ],
      });

      await alert.present();
  }

  async generateListHTML(data: any[]) {
  let html = '<ion-grid>';

  html += `
    <ion-row class="ion-text-uppercase ion-text-sm">
      <ion-col size="1"><strong>Case</strong></ion-col>
      <ion-col size="6"><strong>Id</strong></ion-col>
      <ion-col size="5"><strong>Ref</strong></ion-col>
    </ion-row>
  `;

  data.forEach((item, index) => {
    html += `
      <ion-row class="ion-text-sm">
        <ion-col size="1">${index + 1}</ion-col>
        <ion-col size="6">${item.id}</ion-col>
        <ion-col size="5">${item.ref}</ion-col>
      </ion-row>
    `;
  });

  html += '</ion-grid>';
  return html;
}
  async getForgotLoginDetails(data){
    this.commonService.showLoader();
    this.authService.getForgotLoginDetails(data).subscribe(async (res)=>{
      this.commonService.dismissLoader();
      if(res['result'] && res['data']){
        const secondAlert = await this.alertController.create({
          header: 'Login Case Details',
          message: await this.generateListHTML(res['data']),
          cssClass: 'custom-forgot-alert',
          buttons: ['OK']
        });
        await secondAlert.present();
      }else{
        this.commonService.showToast(res['message'],'danger');
      }

    });
}
}
